<template>
  <div style="min-width: 1600px">
    <div
      class="barnner"
      :style="'background-image: url(' + list[imgIndex].url + ');'"
    ></div>
    <!-- :style="'background: url(' + list[imgIndex].url + ');'" -->
    <!-- <div class="box titleinside">
      <div class="nav_ titleinside_child">
        <div
          v-for="item in list"
          :key="item.id"
          class="from_list"
          @click="from_btn(item.id)"
          :class="{ click_sty: clickId == item.id }"
        >
          {{ item.name }}
          <div v-if="clickId == item.id" style="position: relative; top: -6px">
            <div style="background: #e1251b; height: 3px"></div>
            <div style="background: #e3bb58; height: 3px"></div>
          </div>
        </div>
      </div>
      <div class="nav_1" ref="title">
        <div
          v-for="item in list"
          :key="item.id"
          class="from_list"
          @click="from_btn(item.id)"
          :class="{ click_sty: clickId == item.id }"
        >
          {{ item.name }}
          <div v-if="clickId == item.id" style="position: relative; top: -6px">
            <div style="background: #e1251b; height: 3px"></div>
            <div style="background: #e3bb58; height: 3px"></div>
          </div>
        </div>
      </div>
    </div> -->

    <div style="border-bottom: 1px solid #d8d8d8">
      <div class="box titleinside">
        <div class="nav_ titleinside_child">
          <div
            v-for="item in list"
            :key="item.id"
            class="from_list"
            @click="from_btn(item.id)"
            :class="{ click_sty: clickId == item.id }"
          >
            <span :class="{ click_sty: clickId == item.id }">{{
              item.name
            }}</span>
            <div
              v-if="clickId == item.id"
              style="position: relative; top: -4px"
            >
              <div style="background: #941c24; height: 4px"></div>
              <!-- <div style="background: #e3bb58; height: 3px"></div> -->
            </div>
          </div>
        </div>
        <div class="nav_1" ref="title">
          <div
            v-for="item in list"
            :key="item.id"
            class="from_list"
            @click="from_btn(item.id)"
            :class="{ click_sty: clickId == item.id }"
          >
            <span>
              {{ item.name }}
            </span>
            <div
              v-if="clickId == item.id"
              style="position: relative; top: -4px"
            >
              <div style="background: #941c24; height: 4px"></div>
              <!-- <div style="background: #e3bb58; height: 3px"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        overflow: hidden;
        position: absolute;
        height: 630px;
        width: 100%;
        min-width: 1600px;
      "
    >
      <div class="bg"></div>
    </div>
    <div class="news" style="padding: 0 50px">
      <!--  -->
      <div class="swiper-container swiper-container1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide news_top"
            v-for="item in lastNewsList"
            :key="item.newsId"
            @click="toDetail(item)"
          >
            <div class="news_top_right">
              <div class="news_top_right_img">
                <img
                  src="../../../static/newImage/img_loading.png"
                  class="img_loading"
                  v-show="imgSuffix"
                />
                <img :src="item.newsCover + imgSuffix" width="750px" />
                <div class="news_top_right_img_tips">最新内容</div>
              </div>
            </div>
            <div class="news_top_left">
              <div class="moveTop">
                <div class="news_top_left_title">
                  新闻聚焦 |
                  {{
                    item.newsTitle.lenght > 36
                      ? item.newsTitle
                      : item.newsTitle.slice(0, 36) + "..."
                  }}
                </div>

                <div
                  class="details"
                  style="
                    margin: 25px 0;
                    margin-right: 0;
                    height: 90px;
                    font-weight: 500;
                    font-size: 20px;
                    color: #5b5d5f;
                    line-height: 36px;
                  "
                >
                  {{
                    item.newsIntroduction.lenght > 90
                      ? item.newsIntroduction
                      : item.newsIntroduction.slice(0, 90) + "..."
                  }}
                </div>
              </div>
              <div style="width: 150px; height: 20px">
                <div
                  style="position: relative; cursor: pointer"
                  @click="thereJunp(newsList[0], 22)"
                >
                  <div
                    style="
                      position: absolute;
                      left: 2px;
                      bottom: -98px;
                      font-size: 20px;
                      color: #c51722;
                    "
                  >
                    查看更多
                  </div>
                  <div style="position: absolute; left: 96px; bottom: -97px">
                    <img
                      class="one"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                      width="15px"
                      height="15px"
                    />
                    <img
                      class="two"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                      width="15px"
                      height="15px"
                    />
                    <img
                      class="three"
                      src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-01-15/f146e165ab0941979011d6798463056f.png"
                      height="15px"
                      width="15px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination swiper-pagination1"></div>
      </div>

      <!--  -->
      <div class="news_line"></div>
      <div class="news_list">
        <div
          v-for="list in newsList"
          :key="list.newsId"
          @click="toDetail(list)"
          class="title_btn moveTop"
        >
          <!-- max-width: 485px; -->
          <div
            style="
              height: 211px;
              text-align: center;
              position: relative;
              overflow: hidden;
            "
          >
            <div class="list_type" width="105px">
              {{ filterNews }}
            </div>
            <img
              src="../../../static/newImage/img_loading.png"
              class="img_loading"
              v-show="imgSuffix"
            />
            <img
              v-if="list.newsCover"
              class="news_cover"
              :src="list.newsCover + imgSuffix"
              style="max-width: 100%; margin: 0 auto"
            />
            <img
              v-else
              src="../../../static/newImage/default.jpg"
              class="news_cover"
              style="width: 100%; height: 195px; margin: 0 auto"
            />
          </div>
          <div style="margin: 25px">
            <div
              style="
                height: 49px;
                margin-top: 24px;
                margin-bottom: 28px;
                font-size: 20px;
                font-weight: 500;

                overflow: hidden;
                line-height: 26px;
              "
            >
              {{ list.newsTitle }}
            </div>
            <div
              style="
                display: flex;
                font-size: 14px;
                color: #6b6b6b;
                margin-bottom: 6px;
              "
            >
              <div>{{ filterNews }}</div>
              &nbsp;|&nbsp;
              <div style="margin-top: 1px">
                {{ list.publishTime }}
              </div>
            </div>
            <!-- v-html="list.newsAbstract" -->
            <div
              class="details"
              v-html="
                list.newsIntroduction && list.newsIntroduction.length > 42
                  ? `${list.newsIntroduction.substring(0, 42) + '...'} `
                  : list.newsIntroduction
              "
              style="
                font-size: 14px;
                margin-bottom: 40px;
                height: 39px;
                line-height: 20px;
                color: #6b6b6b;
              "
            ></div>
          </div>
        </div>
      </div>
      <!-- <el-pagination layout="prev, pager, next" @current-change="handleCurrentChange" :total="query.total"
        class="pagination">
      </el-pagination> -->
      <div
        v-show="showMore"
        class="showMore"
        v-loading="isLoading"
        element-loading-text="正在加载更多"
        customClass="showMore"
        element-loading-spinner="el-icon-loading"
        style="text-align: center; font-size: 30px; color: transparent"
      >
        正在加载更多
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper";
import axios from "axios";
import { getAccessToken, getSignature } from "@/api/index";
export default {
  name: "press_center",

  data() {
    return {
      showMore: false,
      isLoading: false,
      imgSuffixTime: null,
      imgSuffix: "?x-oss-process=image/resize,m_lfit,w_500",
      clickId: 17,
      filterNews: "集团要闻",
      list: [
        {
          id: 17,
          lastId: 11,
          name: "集团要闻",
          url: require("../../../static/newImage/位图备份_8.png"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/de3dc05e4f6a4f01b9328b6e72b590e7.png",
        },
        {
          id: 16,
          lastId: 12,
          name: "产业动态",
          url: require("../../../static/newImage/位图备份_13.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/e6c36e9d6a0e45d98e3222ba952d3598.png",
        },
        {
          id: 15,
          lastId: 13,
          name: "媒体报道",
          url: require("../../../static/newImage/位图备份_14.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/49226147719243168806c4b295851612.png",
        },
        {
          id: 27,
          lastId: 29,
          name: "政府支持",
          url: require("../../../static/newImage/位图备份_15.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/09b77e037cca4b4b9cef962dd88ccad3.png",
        },
        {
          id: 28,
          lastId: 30,
          name: "社会贡献",
          url: require("../../../static/newImage/位图备份_16.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/9295f5efcb374322b4ae853bb276f96b.png",
        },
        {
          id: 24,
          lastId: 31,
          name: "热点专题",
          url: require("../../../static/newImage/位图备份_17.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/a44de5fd1fdc4f7d9e0144041c8ffe43.png",
        },
        {
          id: 22,
          lastId: 32,
          name: "集团新闻",
          // url: require("../../../static/newImage/位图7.png"),
          url: require("../../../static/newImage/位图备份_18.jpg"),
          wxUrl:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2025-02-26/9d745b1bc6864f87a5bb3e19472644e7.png",
        },
      ],
      query: {
        current: 1,
        size: 12,
        total: 0,
      },
      newsList: [],
      lastNewsList: [],
      imgIndex: 0,
      container1: null,
      slideIndex: 0,
      XX: null,
    };
  },

  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 4000);
    if (
      document.getElementsByClassName("nav_1")[0] &&
      document.getElementsByClassName("nav_1")[0].parentElement.tagName ==
        "BODY"
    ) {
      document.getElementsByClassName("nav_1")[0].remove();
    }
    window.scrollTo(0, 0);
    this.clickId = this.$route.query.websiteCategoryId;
    const obj = this.list.filter((val) => {
      return val.id == this.clickId;
    });
    if (obj.length > 0) {
      this.filterNews = obj[0].name;
    } else {
      this.filterNews = "集团新闻";
    }
    Swiper.use([Pagination, Autoplay]);
    this.getList();
    this.getLatestList();
    this.$nextTick(() => {
      this.container1 = new Swiper(".swiper-container1", {
        loop: true,
        observer: true, //开启动态检查器，监测swiper和slide
        observeParents: true, //监测Swiper 的祖/父元素
        pagination: {
          el: ".swiper-pagination1",
          clickable: true,
        },
        on: {
          // 监听分页符点击事件
          slideChange: function () {
            console.log("Slide changed, current index: " + this.activeIndex);
          },
        },
      });
    });
    // setInterval(() => {
    //   this.slideIndex += 1;
    //   if (this.slideIndex == 3) {
    //     this.slideIndex = 0;
    //   }
    //   this.container1.slideTo(this.slideIndex);
    // }, 5000);
    // 滚动
    console.log(navigator.userAgent);
    // this.XX = navigator.userAgent;
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.addEventListener("scroll", this.changeTitle);
      document.querySelector(".news").style.minHeight = "1600px";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      window.addEventListener("scroll", this.changeTitle);
      document.querySelector(".news").style.minHeight = "1600px";
    } else {
      window.addEventListener("scroll", this.changeTitle);
    }
    this.getWXInfo();
  },
  beforeDestroy() {
    clearTimeout(this.imgSuffixTime);
    // window.removeEventListener("scroll", this.changeTitle);
    // document.getElementsByClassName("nav_1")[0].remove();
    // if (document.getElementsByClassName("nav_1")[1]) {
    //   document.getElementsByClassName("nav_1")[1].remove();
    // }
  },
  methods: {
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.data.timestamp;
        const nonceStr = res.data.data.noncestr;
        const signature = res.data.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "中峪" + that.list[that.imgIndex].name, // 分享标题
            desc: "立民族志气 紧跟国家战略导向 实现产业融合创新 务实解决民之所需", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.list[that.imgIndex].wxUrl, // 分享图标
          };

          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    changeTitle() {
      console.log(document.getElementsByClassName("showMore"));
      // let rect = null;
      const viewHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const viewWidth =
        window.innerWidth || document.documentElement.clientWidth;
      // if (document.getElementsByClassName("showMore")) {
      let rect = document
        .getElementsByClassName("showMore")[0]
        .getBoundingClientRect();
      if (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= viewHeight &&
        rect.right <= viewWidth &&
        !this.isLoading &&
        this.showMore
      ) {
        this.isLoading = true;
        setTimeout(() => {
          this.query.current += 1;
          this.getList();
        }, 1000);
      }
      // }

      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return;
      } else if (/(Android)/i.test(navigator.userAgent)) {
        return;
      }
      if (window.scrollY > 780) {
        document.getElementsByClassName("titleinside_child")[0].style =
          "opacity: 0";
        this.haveTitle = false;
      } else if (window.scrollY < 700) {
        if (document.getElementsByClassName("titleinside_child")[0]) {
          document.getElementsByClassName("titleinside_child")[0].style =
            "opacity: 1";
          this.$refs["title"].style = "display: none";
          this.haveTitle = true;
        } else {
          if (document.getElementsByClassName("nav_1")[0]) {
            document.getElementsByClassName("nav_1")[0].remove();
          }
          if (document.getElementsByClassName("nav_1")[1]) {
            document.getElementsByClassName("nav_1")[1].remove();
          }
        }
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/press_center_DetailPage",
        query: { v: item.newsId, websiteCategoryId: this.clickId },
      });
    },
    from_btn(id) {
      // window.scrollTo(0, 300);
      window.scrollTo({
        top: 2,
        left: 0,
        behavior: "smooth",
      });
      this.clickId = id;
      this.query.current = 1;
      this.newsList = [];
      this.showMore = false;
      this.$router.push({
        path: "/press_center",
        query: { websiteCategoryId: id },
      });
      this.getLatestList();
      this.getList();
    },
    getList() {
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?current=${this.query.current}&size=${this.query.size}&websiteCategoryId=${this.clickId}`
        )
        .then(async (res) => {
          this.newsList = this.newsList.concat(res.data.data.list);
          this.query.total = res.data.data.total;
          this.showMore = res.data.data.hasNextPage;
          this.isLoading = false;
        });
    },
    getLatestList() {
      const obj = this.list.filter((val) => {
        return val.id == this.clickId;
      });
      axios
        .get(
          `https://admin.zoneyu.cn/cms/api/app/news/list?websiteCategoryId=${obj[0].lastId}&size=3&current=1`
        )
        .then((res) => {
          this.lastNewsList = res.data.data.list;
          console.log(this.lastNewsList);
        });
    },
    handleCurrentChange(val) {
      this.query.current = val;
    },
  },
  watch: {
    $route(val) {
      this.clickId = val.query.websiteCategoryId;
      const obj = this.list.filter((val) => {
        return val.id == this.clickId;
      });
      this.query.current = 1;
      this.newsList = [];
      this.showMore = false;
      this.filterNews = obj[0].name;
      this.getList();
      this.getLatestList();
      this.getWXInfo();
    },
    clickId: function (val) {
      this.list.forEach((val1, index) => {
        if (val1.id == val) {
          this.imgIndex = index;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.barnner {
  width: 100%;
  height: 520px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 140px;
  }
}

.box {
  height: 100%;

  .nav_ {
    height: 70px;
    display: flex;
    padding: 0 20px;
    margin: 0 auto;
    width: 1560px;
    font-size: 20px;
    line-height: 70px;
    justify-content: space-between;
    // border-bottom: 1px solid #d8d8d8;

    .from_list {
      width: 120px;
      text-align: center;
      cursor: pointer;
      /* 更改鼠标样式为手 */
      position: relative;

      .click_sty {
        color: #941c24 !important;
        font-size: 22px;
      }
    }
  }
}

.news {
  max-width: 1500px;
  margin: 60px auto;
  height: 100%;
  // min-height: 1600px;

  .news_top {
    display: flex;
    justify-content: center;
    cursor: pointer;

    .news_top_left {
      width: 48%;
      margin-left: 40px;

      .news_top_left_tips {
        margin-bottom: 10px;
        color: red;
        font-size: 24px;
      }

      .news_top_left_title {
        font-size: 32px;
        width: 100%;
        font-weight: 600;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .news_top_left_time {
        margin-top: 20px;
        font-size: 14px;
        line-height: 12px;
      }

      .news_top_left_more {
        font-size: 14px;
        height: 22px;
        margin: 50px 0 0 0;
        // margin: 50px 0 32px 0;
      }
    }

    .news_top_right {
      .news_top_right_img {
        position: relative;
        // width: 650px;
        // height: 326px;
        width: 766px;
        height: 370px;
        overflow: hidden;

        img {
          transform: scale(1.3);
        }

        .news_top_right_img_tips {
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          position: absolute;
          padding: 7px 20px;
          // background: #ddb563;
          background: url("../../newImage/red.png");
          z-index: 9;
        }
      }
    }
  }

  .news_line {
    height: 1px;
    width: 100%;
    background-color: rgb(239, 239, 239);
    margin: 60px 0;
  }

  .news_list {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 60px;
    justify-content: flex-start;
    max-width: 1600px;

    .list_type {
      // color: #fff;
      color: #52351d;
      position: absolute;
      font-size: 18px;

      padding: 7px 20px;
      // background: #ddb563;
      background: url("../../newImage/yellow.png");
      z-index: 9;
    }

    .title_btn {
      width: calc(33% - 20px);
      height: 100%;
      cursor: pointer;
      justify-content: space-around;
      background-color: #f8f8f8;
      // margin: 0 20px;
      margin-bottom: 24px;
    }

    .title_btn .news_cover {
      max-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    /* 实现超出三行隐藏 */
    .details {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.swiper-container1 {
  --swiper-theme-color: #e11515;
}

.swiper-pagination1 {
  text-align: left;
  padding-left: 800px;
  z-index: 999;
  bottom: 0;

  ::v-deep .swiper-pagination-bullet {
    // width: 40px;
    // height: 5px;
    border-radius: 0;
    margin: 0 8px;
    z-index: 999;
    width: 70px;
    cursor: pointer;
    height: 6px;
    background: #c51722;
  }
}

.el-pagination {
  margin-top: 24px;
  padding-left: 450px;
  margin-bottom: 24px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
::v-deep .showMore .el-loading-spinner .el-loading-text {
  color: #ddb563;
}
::v-deep .showMore .el-loading-spinner i {
  color: #ddb563;
}
.img_loading {
  position: absolute;
  right: 10px;
  width: 20px;
  z-index: 19;
  top: 10px;
  animation: identifier 2s linear infinite;
}
@keyframes identifier {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.one {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0s infinite;
}
.two {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0.4s infinite;
}
.three {
  opacity: 0;
  animation: donghua 1.5s ease-in-out 0.8s infinite;
}
@keyframes donghua {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.bg {
  background: url("../../newImage/bg_.jpg");
  width: 100%;
  height: 800px;
  min-width: 1600px;
  position: absolute;
  background-size: cover;
  z-index: -10;
  background-repeat: no-repeat;
  transform: translateY(-35%);
}
.title_btn:nth-child(3n + 2) {
  margin: 0 30px;
}
</style>
